import { useEffect, useState } from "react";
import StoryblokClient from "storyblok-js-client";

export const resolveStoryBlokRelations = [
  "project_intro.client",
  "project_intro.copywriting",
  "project_intro.creative_tags",
  "project_testimony.testimony",
  "project_page.category",

  "project_overview.selected_fundraising_projects",
  "project_overview.selected_branding_projects",
  "project_overview.selected_digital_projects",
  "project_overview.selected_publications_projects",
  
  "portfolio_overview.partner",
  "categorized_projects.category",
  "categorized_projects.selected_projects",

  "footer_section_global_reference.extended_footer",

  "article_overview.selected_items",
  "article_overview.global_events_news_section",
];

export const Storyblok = new StoryblokClient({
  accessToken: process.env.STORY_BLOK_PREVIEW_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export function useStoryblok(originalStory, preview) {
  let [story, setStory] = useState(originalStory);

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== "undefined") {
      // initialize the bridge with your token
      // const storyblokInstance = new StoryblokBridge();
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: resolveStoryBlokRelations,
      });

      // reload on Next.js page on save or publish event in the Visual Editor
      storyblokInstance.on(["change", "published"], () =>
        location.reload(true)
      );

      // live update the story on input events
      storyblokInstance.on("input", (event) => {
        // check if the ids of the event and the passed story match
        if (story && event.story.content._uid === story.content._uid) {
          // change the story content through the setStory function
          setStory(event.story);
        }
      });

      storyblokInstance.on("enterEditmode", (event) => {
        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          version: "draft",
          resolve_relations: resolveStoryBlokRelations,
        })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById("storyblokBridge");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        // once the script is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // only load inside preview mode
    if (preview) {
      // first load the bridge, then initialize the event listeners
      addBridge(initEventListeners);
    }
  }, [originalStory, preview, setStory]); // runs the effect only once & defines effect dependencies

  useEffect(() => {
    setStory(originalStory);
  }, [originalStory]);

  return story;
}

function resolvePageGlobalSections(story) {
  const global_sections = story?.content?.global_sections || [];

  const footer =
    global_sections.find(
      (item) => item.component === "footer_section_global_reference"
    ) || {};

  return { footer };
}

function resolveCustomPageConfigurations(story) {
  const custom_page_configuration =
    story?.content?.custom_page_configuration || [];

  const seo =
    custom_page_configuration.find((item) => item.component === "seo") || {};

  return { seo };
}

async function resolveOurWorkStory({ fullSlug, sbParams }) {
  // This data request will give only the selected items on 'our-work' CMS folder.
  // Therefore, when an editor adds a new item, we are not able to get it here yet.
  // Another api call will be necessary if fullSlug is not present here. See below.
  const { data } = await Storyblok.get(`cdn/stories/our-work`, sbParams);
  const allSelectedProjects = data.story || false;

  const [categoryName] =
    fullSlug?.replace("our-work/", "").split("/") || [];

  // if there's no project slug it means they are on 'Our Work overview page'
  if (
    fullSlug === "our-work/" ||
    fullSlug === "our-work/fundraising" ||
    fullSlug === "our-work/branding" ||
    fullSlug === "our-work/digital" ||
    fullSlug === "our-work/publications"
  ) {
    return {
      story: allSelectedProjects,
      additionalData: {
        navigation: false,
        fullSlug,
        categoryName: categoryName || "fundraising",
        categorySlug: categoryName ? `our-work/${categoryName}` : "our-work/",
      },
    };
  }

  let projectsOrdered = [];
  const {
    selected_fundraising_projects,
    selected_branding_projects,
    selected_digital_projects,
    selected_publications_projects,
  } = allSelectedProjects?.content || {};

  if (selected_fundraising_projects?.length > 0) {
    projectsOrdered = [...projectsOrdered, ...selected_fundraising_projects];
  }
  if (selected_branding_projects?.length > 0) {
    projectsOrdered = [...projectsOrdered, ...selected_branding_projects];
  }
  if (selected_digital_projects?.length > 0) {
    projectsOrdered = [...projectsOrdered, ...selected_digital_projects];
  }
  if (selected_publications_projects?.length > 0) {
    projectsOrdered = [...projectsOrdered, ...selected_publications_projects];
  }

  let story = projectsOrdered.find(
    (item) => item.full_slug === fullSlug
  );

  if (!story) {
    // Content editors haven't select this project story on 'our-work' CMS folder.
    // We need to directly fetch the story because they are probably still creating it.
    const { data } = await Storyblok.get(
      `cdn/stories/${fullSlug}`,
      sbParams
    );
    story = data.story || false;

    // We don't resolve the navigation if they haven't selected this story yet.
    return {
      story,
      additionalData: {
        navigation: false,
        fullSlug,
        categoryName: categoryName || "fundraising",
        categorySlug: categoryName ? `our-work/${categoryName}` : "our-work/",
      },
    };
    // return { story, fullSlug, categoryName, navigation: null };
    
  }

  // We resolve the navigation because it is a selected story
  const allSelectedProjectsSlug =
    projectsOrdered.length > 0 ? projectsOrdered.map((_) => _.full_slug) : [];

  const navigation = allSelectedProjectsSlug
    .map((key, index) => ({
      current: key,
      prev: allSelectedProjectsSlug[
        index - 1 >= 0 ? index - 1 : allSelectedProjectsSlug.length - 1
      ],
      next: allSelectedProjectsSlug[
        index + 1 <= allSelectedProjectsSlug.length - 1 ? index + 1 : 0
      ],
    }))
    .find((item) => item.current === fullSlug);

    return {
      story,
      additionalData: {
        navigation,
        fullSlug,
        categoryName: categoryName || "fundraising",
        categorySlug: categoryName ? `our-work/${categoryName}` : "our-work/",
      },
    };
  // return { story, fullSlug, categoryName, navigation };
}

function reverseArray(array) {
  let start = 0;
  let end = array.length - 1;

  while (start < end) {
    // Swap elements at the start and end
    [array[start], array[end]] = [array[end], array[start]];

    // Move towards the center of the array
    start++;
    end--;
  }

  return array;
}

async function resolveOurStudioStory({ fullSlug, sbParams }) {
  // This data request will give only the selected items on 'our-work' CMS folder.
  // Therefore, when an editor adds a new item, we are not able to get it here yet.
  // Another api call will be necessary if fullSlug is not present here. See below.
  const { data } = await Storyblok.get(`cdn/stories/our-studio`, sbParams);
  const reversedArticlesOrder = {...data.story, content: {...data.story.content, selected_items: reverseArray(data.story.content.selected_items)}}

  const allSelectedArticles = reversedArticlesOrder || false; //

  const { global_events_news_section, selected_items } =
    allSelectedArticles?.content || {};

  const [categoryName] = fullSlug?.replace("our-studio/", "").split("/") || [];

  // if there's no other slug it means they are on 'Our Studio overview page'
  if (
    fullSlug === "our-studio/" ||
    fullSlug === "our-studio/our-people" ||
    fullSlug === "our-studio/our-craft" ||
    fullSlug === "our-studio/our-thinking"
  ) {
    return {
      story: allSelectedArticles,
      additionalData: {
        fullSlug,
        categoryName: categoryName || "all",
        categorySlug: categoryName ? `our-studio/${categoryName}` : "our-studio/",
      },
    };
  }

  let story = selected_items.find((item) => item.full_slug === fullSlug);

  if (!story) {
    // Content editors haven't select this item story on 'our-studio' CMS folder.
    // We need to directly fetch the story because they are probably still creating it.
    const { data } = await Storyblok.get(`cdn/stories/${fullSlug}`, sbParams);
    // We don't resolve the navigation if they haven't selected this story yet.
    story = data.story || false;
  }

  return {
    story,
    additionalData: {
      allSelectedArticles,
      fullSlug,
      categoryName: categoryName || "all",
      categorySlug: categoryName ? `our-studio/${categoryName}` : "our-studio/",
      globalEventsNewsSection: global_events_news_section,
    },
  };
}

async function resolveStory({ fullSlug, sbParams }) {
  const isOurWorkRoute = fullSlug.includes("our-work");
  const isOurStudioRoute = fullSlug.includes("our-studio");

  if (isOurWorkRoute) {
    const ourWorkStory = await resolveOurWorkStory({
      fullSlug,
      sbParams,
    });

    return ourWorkStory;
  }

  if (isOurStudioRoute) {
    const ourStudioStory = await resolveOurStudioStory({
      fullSlug,
      sbParams,
    });

    return ourStudioStory;
  }

  const { data } = await Storyblok.get(`cdn/stories/${fullSlug}`, sbParams);

  return { story: data ? data.story : false };
}

export async function getAsyncPageProps({ fullSlug, sbParams }) {
  const resolvedStory = await resolveStory({
    fullSlug,
    sbParams,
  });
  const resolvedPageGlobalSections = resolvePageGlobalSections(
    resolvedStory.story
  );
  const resolvedCustomPageConfigurations = resolveCustomPageConfigurations(
    resolvedStory.story
  );

  return {
    ...resolvedStory,
    ...resolvedPageGlobalSections,
    ...resolvedCustomPageConfigurations,
  };
}
